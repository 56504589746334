import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'

import VueSocketIO from 'vue-3-socket.io'
import SocketIO from "socket.io-client"

// Global Varaibles

//
global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

//
window.api = "http://127.0.0.1:3000"
window.api = "https://serversakani.brmja.com"
// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.use(new VueSocketIO({
  debug: false,
  transports: ['websocket'],
  connection: SocketIO(window.api, {
      transports: ['websocket'],
      auth: {
        jwt: JSON.parse(localStorage.getItem("user"))?.jwt,
        user_id: JSON.parse(localStorage.getItem("user"))?._id,
      }
  })
}))

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
